import React, { useState, useEffect, useCallback, useRef } from "react";
import gold from "../../assets/Images/demo/gold.png";
import mobile from "../../assets/Images/demo/mobile.png";
import slider1 from "../../assets/Images/demo/slider_2_guac.png";
import slider2 from "../../assets/Images/demo/guac_slider_3.svg";
import arrow_back_ios_new from "../../assets/Images/arrow_back_ios_new.png";
import guac from "../../assets/Images/demo/hfk_logo.svg";
import green from "../../assets/Images/demo/guac_green.svg";
import favourite from "../../assets/Images/demo/favorite.svg";
import circle from "../../assets/Images/demo/check_circle.svg";
import dangerous from "../../assets/Images/demo/dangerous.svg";
import "../../assets/css/style.css";
import { useNavigate } from "react-router-dom";
import { Config } from "../../Utils/AppConstants";
import { makeApiCall } from "../../services/ApiService";
import forward_arrow from "../../assets/Images/forward_arrow.png";

import {
  API_CALL_TYPE,
  GET_ACTIVE_SUBSCRIPTION,
  GET_ALL_FINTECHS,
  GET_BILLING_ADDRESSES,
  GET_SUBSCRIPTION_PLANS,
  POST_PAYMENT_METHOD,
  SAVE_SESSION,
  UPDATE_SESSION,
} from "../../services/Api";
import { useQueryClient } from "react-query";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setSessionData,
  setLoading,
  setError,
  setDynamicLink,
  SessionState,
  setSessionIDStore,
  setAsaConsumerCode,
} from "../../redux/sessionSlice";
import { SessionData, SessionUpdateRequest } from "../../types/types";
import { ToastContentProps, toast } from "react-toastify";
import LoaderComponent from "../../components/LoaderComponent";
import check_circle from "../../assets/Images/demo/check_circle_portfolio.svg";
import add_task from "../../assets/Images/demo/add_task_portfolio.svg";
import star from "../../assets/Images/demo/star_gold.svg";
import add_task_platinum from "../../assets/Images/demo/add_task.svg";
import star_platinum from "../../assets/Images/demo/star.svg";
import smart_toy from "../../assets/Images/demo/smart_toy.svg";
import manage_search from "../../assets/Images/demo/manage_search.svg";
import biotech from "../../assets/Images/demo/biotech.svg";
import "./HFKLanding.css"; // Import your CSS file for animations

interface Slide {
  icon: string;
  title: string;
  description: string;
}

interface CustomSliderProps {
  slides: Slide[];
}

interface BillingAddress {
  consumerAddressId: number;
  address1: string;
  address2: string | null;
  city: string;
  state: string;
  zipCode: string;
  firstName: string;
  lastName: string;
  country: string;
}

interface PaymentMethod {
  arrow_open: any;
  dropdown_arrow_close: any;
  paymentMethodId: number;
  asaPaymentMethodCode: number;
  isDefault: boolean;
  paymentMethodName: string;
  paymentType: string;
  routingNumber: string | null;
  last4DigitsOfAccountNumber: string | null;
  bankName: string;
  cardNumber: string | null;
  accountNumber: string | null;
  cardType: string | null;
  last4DigitsOfCardNumber: string | null;
  firstName: string | null;
  middleName: string | null;

  lastName: string | null;
  cardHolderName: string | null;
  expirationYear: string | null;
  expirationMonth: string | null;
}

interface CustomSliderProps {
  slides: Slide[];
  activePlanCodes: Set<any>; // Add activePlanCodes prop
}

const CustomSlider: React.FC<CustomSliderProps> = ({
  slides,
  activePlanCodes,
}) => {
  const navigate = useNavigate();
  const [showSubscriptionOptions, setShowSubscriptionOptions] = useState(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [popupMessage, setPopupMessage] = useState<string>("");
  const [plans, setPlans] = useState<any[] | null>(null);

  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const startXRef = useRef<number>(0);
  const dragOffsetRef = useRef<number>(0);
  const [dragOffset, setDragOffset] = useState<number>(0);

  const UpdatedLocalData = useSelector(
    (state: SessionState) => state?.session.sessionData
  );

  const handleStart = (clientX: number) => {
    startXRef.current = clientX;
    setIsDragging(true);
  };

  const handleMove = (clientX: number) => {
    if (!isDragging) return;
    const diff = startXRef.current - clientX;
    dragOffsetRef.current = diff;
    setDragOffset(diff);
  };

  const handleEnd = () => {
    if (!isDragging) return;
    const threshold = (sliderRef.current?.offsetWidth ?? 0) / 3;

    if (Math.abs(dragOffsetRef.current) > threshold) {
      if (dragOffsetRef.current > 0 && currentSlide < slides.length - 1) {
        setCurrentSlide((prev) => prev + 1);
      } else if (dragOffsetRef.current < 0 && currentSlide > 0) {
        setCurrentSlide((prev) => prev - 1);
      }
    }

    setIsDragging(false);
    setDragOffset(0);
  };

  const handleDotClick = (index: number) => {
    setCurrentSlide(index);
  };

  const handleContinue = () => {
    if (!UpdatedLocalData) {
      // Exit early if there's no UpdatedLocalData
      return;
    }

    // Extract sessionPages from UpdatedLocalData
    const sessionPages = UpdatedLocalData.data.sessionPages;

    if (!sessionPages || sessionPages.length === 0) {
      // If sessionPages array is empty or not found
      return;
    }

    // Iterate over sessionPages to find the "SUBSCRIPTION PLAN" page
    sessionPages.forEach((page: { pageName: string; pageData: string }) => {
      try {
        if (page.pageName === "SUBSCRIPTION PLAN") {
          // Parse the page data
          const parsedPageData = JSON.parse(page.pageData.replace(/\\/g, ""));

          // Check if parsedPageData exists
          if (parsedPageData) {
            // Extract the plan ID (replace subscriptionPlanCode with the correct key if needed)
            const planId = parsedPageData?.subscriptionPlanCode;

            // Check if this plan is already active
            if (activePlanCodes.has(planId)) {
              setPopupMessage(`This Subscription plan is already active.`);
              setShowPopup(true); // Show the popup
            } else {
              // If the plan is not active, navigate to the next page
              navigate("/ListPay");
            }
          }
        }
      } catch (error) {
        console.error("Error parsing page data:", error);
      }
    });
  };

  const handleBack = () => {
    if (UpdatedLocalData) {
      const sessionPages = UpdatedLocalData.data.sessionPages;
      let backActionDynamicLink = null;

      for (let i = 0; i < sessionPages.length; i++) {
        if (sessionPages[i].pageNumber === 0) {
          const pageData = JSON.parse(sessionPages[i].pageData);
          backActionDynamicLink = pageData.backActionDynamicLink;
          break;
        }
      }

      if (backActionDynamicLink) {
        window.location.href = backActionDynamicLink;
      } else {
        console.error("Back action dynamic link not found.");
      }
    } else {
      console.error("UpdatedLocalData is not available.");
    }
  };
  useEffect(() => {
    const slider = sliderRef.current;

    if (!slider) return;

    const touchStart = (e: TouchEvent) => handleStart(e.touches[0].clientX);
    const touchMove = (e: TouchEvent) => handleMove(e.touches[0].clientX);
    const touchEnd = () => handleEnd();

    const mouseDown = (e: MouseEvent) => handleStart(e.clientX);
    const mouseMove = (e: MouseEvent) => handleMove(e.clientX);
    const mouseUp = () => handleEnd();

    slider.addEventListener("touchstart", touchStart, { passive: true });
    slider.addEventListener("touchmove", touchMove, { passive: true });
    slider.addEventListener("touchend", touchEnd);
    slider.addEventListener("mousedown", mouseDown);
    window.addEventListener("mousemove", mouseMove);
    window.addEventListener("mouseup", mouseUp);

    return () => {
      slider.removeEventListener("touchstart", touchStart);
      slider.removeEventListener("touchmove", touchMove);
      slider.removeEventListener("touchend", touchEnd);
      slider.removeEventListener("mousedown", mouseDown);
      window.removeEventListener("mousemove", mouseMove);
      window.removeEventListener("mouseup", mouseUp);
    };
  }, [isDragging, slides.length]);

  return (
    <div
      ref={sliderRef}
      style={{
        boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)",
      }}
      className="relative w-full bg-[#EBECF0] border-[3.5px] border-yellow-500 rounded-2xl overflow-hidden flex flex-col h-auto mt-8"
    >
      <div className="absolute top-2 left-2 z-10 --guac-img-gold">
        <img src={gold} className="object-contain w-16" alt="Gold" />
      </div>

      <div
        className="w-full bg-[#FBEFCF] flex-shrink-0"
        style={{ height: "193px" }}
      >
        <div
          className="flex transition-transform duration-300 ease-out h-full"
          style={{
            transform: `translateX(calc(-${currentSlide * 100}% + ${
              isDragging ? -dragOffset : 0
            }px))`,
            transition: isDragging ? "none" : "transform 300ms ease-out",
          }}
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              className="w-full flex-shrink-0 p-4 flex justify-center items-center __asa_guac_slider"
            >
              <div className="--side-image">
                <img
                  src={slide.icon}
                  alt={slide.title}
                  className="max-w-full h-full object-contain"
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col overflow-y-hidden h-auto sm:h-60 bg-yellow-50 justify-between p-4">
        <div className="overflow-y-auto">
          <h3 className=" mb-2 text-black guac-slider-title font-poppins">
            {slides[currentSlide].title}
          </h3>
          <p className="guac-slider-description font-poppins">
            {slides[currentSlide].description}
          </p>
        </div>

        <div className="flex justify-center space-x-2 my-4">
          {slides.map((_, index) => (
            <div
              key={index}
              onClick={() => handleDotClick(index)}
              className={`w-2 h-2 rounded-full cursor-pointer ${
                index === currentSlide ? "bg-[#EBAE11]" : "bg-gray-300"
              }`}
            />
          ))}
        </div>

        <button
          onClick={handleContinue}
          className="w-full bg-[#EBAE11] text-white  py-3 rounded-lg shadow-md font-poppins font-semibold"
          style={{
            boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)",
          }}
        >
          CONTINUE
        </button>

        {/* Enhanced Popup Modal */}
        {showPopup && (
          <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50 transition-opacity duration-300 ease-in-out">
            <div className="bg-white p-6 rounded-lg shadow-xl max-w-sm mx-4 sm:mx-8 lg:mx-12">
              <div className="flex flex-col items-center">
                <div className="text-center mb-4">
                  <p className="font-poppins text-xl font-poppins text-gray-800">
                    {popupMessage}
                  </p>
                </div>
                <button
                  className="bg-[#1B9C83] text-white px-4 py-2 rounded-md font-semibold hover:bg-[#169b6b] transition-colors duration-200"
                  onClick={() => setShowPopup(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default function HFKLanding() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activePlans, setActivePlans] = useState([]);
  const [showSubscriptionOptions, setShowSubscriptionOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const [billingAddresses, setBillingAddresses] = useState<BillingAddress[]>(
    []
  ); // State for billing addresses
  const [subscriptionType, setSubscriptionType] = useState("Yearly");
  const [subscriptionAmount, setsubscriptionAmount] = useState(0);
  const queryClient = useQueryClient();
  const [getSessionCalled, setGetSessionCalled] = useState(false);
  const [plans, setPlans] = useState<any[] | null>(null);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

  const updatedsessionData: SessionUpdateRequest | undefined =
    queryClient.getQueryData("updatedSessionData");
  const UpdatedLocalData = useSelector(
    (state: SessionState) => state?.session.sessionData
  );
  const [activePlanCodes, setActivePlanCodes] = useState<Set<any>>(new Set());
  const [activeSubscriptions, setActiveSubscriptions] = useState<any[]>([]);

  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [popupMessage, setPopupMessage] = useState<string>("");

  const swiperRef = useRef<HTMLDivElement | null>(null); // Specify the correct type
  const [token, setToken] = useState<string | null>(null);
  const [createdSessionData, setcreatedSessionData] =
    useState<SessionData | null>(null); // Define state variable
  const [sessionID, setSessionID] = useState<string | null>(null);
  const [sessionFetched, setSessionFetched] = useState(false); // Track if session has been fetched
  const sessionData: SessionData | undefined =
    queryClient.getQueryData("sessionData");
  var settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const showCustomToast = (
    message:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | Iterable<React.ReactNode>
      | React.ReactPortal
      | ((props: ToastContentProps<unknown>) => React.ReactNode)
      | null
      | undefined,
    isError: boolean = false // Add a parameter to specify if it's an error message
  ) => {
    // Determine the toast type based on whether it's an error or success
    const toastType = isError ? toast.error : toast.success;

    // Show the toast message
    toastType(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        backgroundColor: isError ? "#A2252C" : "#4BB543", // Set the background color
        color: "white", // Set the text color
        fontFamily: "Poppins", // Set the font family
      },
      bodyClassName: "poppins-font", // Add a class for additional styling
    });
  };

  const getSession = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = makeApiCall(
        API_CALL_TYPE.GET_CALL, // Specify the API call type (GET in this case)
        SAVE_SESSION(Config.sessionID), // API endpoint to fetch session data
        handleSessionResponse, // Callback function to handle successful response
        handleSessionError, // Callback function to handle error response
        "", // Placeholder for formData argument
        "", // Placeholder for token argument
        "" // Placeholder for body argument
      );

      return response;
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching session:", error);
      if (!getSessionCalled) {
        setGetSessionCalled(true);
        getSession();
      }
      throw error;
    }
  }, []);

  // useEffect(() => {
  //   if (swiperRef.current) {
  //     const swiper = new Swiper(swiperRef.current, {
  //       slidesPerView: "auto",
  //       spaceBetween: 30,
  //       loop: true,
  //       navigation: {
  //         nextEl: ".swiper-button-next",
  //         prevEl: ".swiper-buttonit-prev",
  //       },
  //     });

  //     return () => {
  //       swiper.destroy();
  //     };
  //   }
  // }, []);

  useEffect(() => {}, [activePlanCodes]);

  useEffect(() => {
    setShowLoader(isLoading); // Show loader when isLoading is true
  }, [isLoading]);

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    // const currentUrl = new URL(Config.BROWSER_URL);
    const sessionID = currentUrl.searchParams.get("sessionid");
    const asaConsumerCode = currentUrl.searchParams.get("asaConsumerCode");
    const versionCode = currentUrl.searchParams.get("version");
    Config.X_ASA_version = versionCode ? parseFloat(versionCode) : 1.08; // const asaFiCode = currentUrl.searchParams.get("asaFiCode");
    const token = currentUrl.searchParams.get("token");
    const asaFicode = currentUrl.searchParams.get("asaFintechCode");
    // const asaFintechCode = currentUrl.searchParams.get("asaFintechCode");

    if (asaFicode !== null) {
      const parsedFicode = parseInt(asaFicode, 10);
      if (!isNaN(parsedFicode)) {
        Config.asaFicode = parsedFicode;
      } else {
        // Handle case where asaFicode is not a valid number
        console.error("Invalid asaFicode:", asaFicode);
      }
    }
    let decodedLink: string | null = null;
    const encodedLink = currentUrl.searchParams.get("dynamicLink");
    if (encodedLink !== null) {
      decodedLink = decodeURIComponent(encodedLink); // Assign decodedLink inside the if block
      dispatch(setDynamicLink(decodedLink)); // Dispatch action to save dynamic link
      queryClient.setQueryData("dynamicLink", decodedLink);
    } else {
      console.error("encodedLink is nulldsfdsfdsf");
    } // Declare decodedLink outside the if block
    if (sessionID && asaConsumerCode) {
      // Update Config object with session ID and subscription key
      Config.sessionID = sessionID;
      Config.asaConsumerCode = parseInt(asaConsumerCode);
      // Save session ID to React Query cache
      queryClient.setQueryData("sessionID", sessionID);
      setSessionID(sessionID);
      dispatch(setSessionIDStore(sessionID));
      dispatch(setAsaConsumerCode(Config.asaConsumerCode));

      // if (sessionID) {
      // If sessionID is not set, call getSession to fetch it
      setSessionFetched(true);
      getSession();

      // }
    } else {
      if (UpdatedLocalData) {
        const sessionPages = UpdatedLocalData?.data?.sessionPages;

        if (!sessionPages || sessionPages.length === 0) {
          // If sessionPages array is empty or not found, fetch PaymentMethodList from API
          // fetchPaymentMethods();
          // fetchBillingAddresses();
          return;
        }

        sessionPages.forEach((page: { pageName: string; pageData: string }) => {
          try {
            if (page.pageName === "SUBSCRIPTION PLAN") {
              const parsedPageData = JSON.parse(
                page?.pageData.replace(/\\/g, "")
              );

              if (parsedPageData) {
                if (parsedPageData?.asaFintechCode !== null) {
                  const parsedFicode = parseInt(
                    parsedPageData?.asaFintechCode,
                    10
                  );
                  if (!isNaN(parsedFicode)) {
                    Config.asaFicode = parsedFicode;
                  } else {
                    // Handle case where asaFicode is not a valid number
                    console.error("Invalid asaFicode:", asaFicode);
                  }
                }
              }
            }
          } catch (error) {
            console.error(`Error processing page '${page.pageName}':`, error);
          }
        });

        Config.sessionID = UpdatedLocalData?.data?.asaSessionCode;
        Config.asaConsumerCode = parseInt(
          UpdatedLocalData?.data?.asaConsumerCode
        );

        const versionCode = UpdatedLocalData?.data?.version;
        Config.X_ASA_version = versionCode ? parseFloat(versionCode) : 1.08; // const asaFiCode = currentUrl.searchParams.get("asaFiCode");

        queryClient.setQueryData("sessionID", Config.sessionID);

        setSessionID(Config.sessionID);
        dispatch(setSessionIDStore(UpdatedLocalData?.data?.asaSessionCode));
        dispatch(setAsaConsumerCode(Config.asaConsumerCode));

        // if (sessionID) {
        // If sessionID is not set, call getSession to fetch it
        setSessionFetched(true);
        getSession();
      }

      //navigate("/error", { state: "CRB AUTHENTICATION ERROR" });
    }
    // }, [sessionID, Config.asaConsumerCode, sessionFetched]); // Run whenever sessionID changes
  }, []); // Run whenever sessionID changes
  /*
    Handle updating session API CALL
 */

  const updateSession = async (_createdSessionDataifany: any) => {
    dispatch(setLoading(true));
    setIsLoading(true);
    const sessionUpdateRequest: SessionUpdateRequest = {
      asaSessionCode: Config.sessionID,
      asaConsumerCode: Config.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT-GUAC",
      pageCount: _createdSessionDataifany?.pageCount,
      status: "Incomplete",
      statusMessage: _createdSessionDataifany?.statusMessage,
      sessionPages: [
        {
          asaSessionCode: Config.sessionID, // Use the session ID
          pageNumber: "1", // Page number, could be a string or a number depending on your API requirements
          pageName: "Landing", // Name of the page (e.g., "Landing")
          pageTitle: "Select Package", // Title of the page (e.g., "Select Package")
          pageDescription: "Select Package for  Rent", // Description of the page
          pageUrl: "/GUAC", // URL of the page
          pageStatus: "Complete", // Status of the page (e.g., "New")
          pageStatusMessage: "Landing Page", // Status message for the page
          pageData: "{}", // Additional data related to the page, could be empty or contain serialized data
        },
      ],
    };

    try {
      const response = makeApiCall(
        API_CALL_TYPE.PUT_CALL, // Specify the API call type (GET in this case)
        UPDATE_SESSION(), // API endpoint to fetch session data
        handleUpdateSessionResponse, // Callback function to handle successful response
        handleUpdateSessionError, // Callback function to handle error response
        "", // Placeholder for formData argument
        "", // Placeholder for token argument
        sessionUpdateRequest // Pass the session ID in the body // Placeholder for body argument
      );
      return response;
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error fetching session:"));
      console.error("Error fetching session:", error);
      throw error;
    }
  };

  // Handling Update Session response

  const handleUpdateSessionResponse = (response: SessionUpdateRequest) => {
    if (response && response?.status == "400") {
      // Handle the invalid session error here
      console.error("Invalid session error:", response.message);
      // You can show a toast message to the user or navigate to an error page
      showCustomToast("Invalid session. Please start again.", true);
      navigate("/error", { state: "Invalid Session" });
    } else {
      // Handle other responses or success cases

      dispatch(setSessionData(response));
      setIsLoading(false);
      queryClient.setQueryData("updatedSessionData", response);

      // Dispatch actions, update state, etc.
    }
  };

  // Handling Update Session Error

  const handleUpdateSessionError = (_error: any) => {
    setIsLoading(false);
    dispatch(setLoading(false));
  };

  // Handling GET Session API CALL

  const updateSession1 = async (
    _createdSessionDataifany: any,
    data: any,
    pageNUmber: string,
    pageName: string,
    pageTitle: string,
    pageDescription: string,
    pageUrl: string,
    pageStatusMessage: string
  ) => {
    dispatch(setLoading(true));
    setIsLoading(true);
    const updateRequest: SessionUpdateRequest = {
      asaSessionCode: Config.sessionID,
      asaConsumerCode: Config.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT-GUAC",
      pageCount: _createdSessionDataifany?.pageCount,
      status: "Incomplete",
      statusMessage: _createdSessionDataifany?.statusMessage,
      sessionPages: [
        {
          asaSessionCode: Config.sessionID,

          pageNumber: pageNUmber, // Page number, could be a string or a number depending on your API requirements
          pageName: pageName, // Name of the page (e.g., "Landing")
          pageTitle: pageTitle, // Title of the page (e.g., "Select Package")
          pageDescription: pageDescription, // Description of the page
          pageUrl: pageUrl, // URL of the page
          pageStatus: "Complete", // Status of the page (e.g., "New")
          pageStatusMessage: pageStatusMessage, // Status message for the page
          pageData: JSON.stringify(data), // Serialize the selected plan data
        },
      ],
    };

    try {
      const sessionID: string = queryClient.getQueryData("sessionID") as string;

      const response = await makeApiCall(
        API_CALL_TYPE.PUT_CALL,
        UPDATE_SESSION(),
        handleUpdateSessionResponse,
        handleUpdateSessionError,
        "",
        "",
        updateRequest
      );

      return response;
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error updating session:"));
      console.error("Error updating session:", error);
      throw error;
    }
  };

  const updateSessiongoal = async (
    _createdSessionDataifany: any,
    data: any,
    pageNUmber: string,
    pageName: string,
    pageTitle: string,
    pageDescription: string,
    pageUrl: string,
    pageStatusMessage: string
  ) => {
    dispatch(setLoading(true));
    setIsLoading(true);
    const updateRequest: SessionUpdateRequest = {
      asaSessionCode: Config.sessionID,
      asaConsumerCode: Config.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT-GUAC",
      pageCount: _createdSessionDataifany?.pageCount,
      status: "Incomplete",
      statusMessage: _createdSessionDataifany?.statusMessage,
      sessionPages: [
        {
          asaSessionCode: Config.sessionID, // Use the session ID
          pageNumber: pageNUmber, // Page number, could be a string or a number depending on your API requirements
          pageName: pageName, // Name of the page (e.g., "Landing")
          pageTitle: pageTitle, // Title of the page (e.g., "Select Package")
          pageDescription: pageDescription, // Description of the page
          pageUrl: pageUrl, // URL of the page
          pageStatus: "Complete", // Status of the page (e.g., "New")
          pageStatusMessage: pageStatusMessage, // Status message for the page
          pageData: JSON.stringify(data), // Serialize the selected plan data
        },
      ],
    };

    try {
      // const sessionID: string = queryClient.getQueryData("sessionID") as string;

      // if (!sessionID) {
      //   throw new Error("Session ID not found");
      // }

      const response = await makeApiCall(
        API_CALL_TYPE.PUT_CALL,
        UPDATE_SESSION(),
        handleUpdateSessionResponse,
        handleUpdateSessionError,
        "",
        "",
        updateRequest
      );

      return response;
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error updating session:"));
      console.error("Error updating session:", error);
      throw error;
    }
  };

  const updateSessiongoalPlan = async (
    _createdSessionDataifany: any,
    data: any,
    pageNUmber: string,
    pageName: string,
    pageTitle: string,
    pageDescription: string,
    pageUrl: string,
    pageStatusMessage: string
  ) => {
    dispatch(setLoading(true));
    setIsLoading(true);
    const updateRequest: SessionUpdateRequest = {
      asaSessionCode: Config.sessionID,
      asaConsumerCode: Config.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT-GUAC",
      pageCount: _createdSessionDataifany?.pageCount,
      status: "Incomplete",
      statusMessage: _createdSessionDataifany?.statusMessage,
      sessionPages: [
        {
          asaSessionCode: Config.sessionID, // Use the session ID
          pageNumber: pageNUmber, // Page number, could be a string or a number depending on your API requirements
          pageName: pageName, // Name of the page (e.g., "Landing")
          pageTitle: pageTitle, // Title of the page (e.g., "Select Package")
          pageDescription: pageDescription, // Description of the page
          pageUrl: pageUrl, // URL of the page
          pageStatus: "Complete", // Status of the page (e.g., "New")
          pageStatusMessage: pageStatusMessage, // Status message for the page
          pageData: JSON.stringify(data), // Serialize the selected plan data
        },
      ],
    };

    try {
      // const sessionID: string = queryClient.getQueryData("sessionID") as string;

      // if (!sessionID) {
      //   throw new Error("Session ID not found");
      // }

      const response = await makeApiCall(
        API_CALL_TYPE.PUT_CALL,
        UPDATE_SESSION(),
        handleUpdateSessionResponsePlans,
        handleUpdateSessionError,
        "",
        "",
        updateRequest
      );

      return response;
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error updating session:"));
      console.error("Error updating session:", error);
      throw error;
    }
  };
  // Handling Update Session response

  const handleUpdateSessionResponsegoal = (response: SessionUpdateRequest) => {
    if (response && response?.status == "400") {
      // Handle the invalid session error here
      console.error("Invalid session error:", response.message);
      // You can show a toast message to the user or navigate to an error page
      showCustomToast("Invalid session. Please start again.", true);
      navigate("/checkout", { state: "Invalid Session" });
    } else {
      // Handle other responses or success cases

      dispatch(setSessionData(response));
      setIsLoading(false);
      queryClient.setQueryData("updatedSessionData", response);

      //navigate("/portfoliopilot");

      // Dispatch actions, update state, etc.
    }
  };

  // Handling Update Session response

  const handleUpdateSessionResponsePlans = (response: SessionUpdateRequest) => {
    if (response && response?.status == "400") {
      // Handle the invalid session error here
      console.error("Invalid session error:", response.message);
      // You can show a toast message to the user or navigate to an error page
      showCustomToast("Invalid session. Please start again.", true);
      navigate("/checkout", { state: "Invalid Session" });
    } else {
      // Handle other responses or success cases

      dispatch(setSessionData(response));
      setIsLoading(false);
      queryClient.setQueryData("updatedSessionData", response);

      navigate("/portfoliopilot");

      // Dispatch actions, update state, etc.
    }
  };

  const handleFetchPlansSuccess = async (data: any) => {
    if (data && data.data) {
      setPlans(data.data);

      setSubscriptionType(data.data?.billingCycle);
      setsubscriptionAmount(data.data?.oneTimeAmount);
      queryClient.setQueryData("selectedPlan", data.data);

      try {
        await updateSessiongoal(
          createdSessionData,
          data?.data,
          "2",
          "SUBSCRIPTION PLAN",
          "Select Package",
          "Select Package",
          "/guac",
          "Completed"
        );
      } catch (error) {
        setIsLoading(false);
        dispatch(setError("Error fetching session:"));
        console.error("Error fetching session:", error);
        throw error;
      }

      // await updateSession1(
      //   createdSessionData,
      //   data.data[0],
      //   "1",
      //   "GOALS PLANS",
      //   "USER SELECTED PLAN",
      //   "/oneGoal",
      //   "INCOMPLETE",
      //   "INCOMPLETE"
      // );
    } else {
      setPlans([]);
    }
  };

  const handleFetchPlansError = (error: any) => {
    console.error("Error fetching subscription plans:", error);
  };

  const handleBillingAddressesResponse = async (data: any) => {
    const formattedBillingAddresses: BillingAddress[] = data.data
      .filter((address: any) => address.isBilling === true)
      .map((address: any) => ({
        consumerAddressId: address.consumerAddressId,
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        state: address.state,
        zipCode: address.zipCode,
        firstName: address.firstName,
        lastName: address.lastName,
        country: address.country,
      }));

    setBillingAddresses(formattedBillingAddresses);

    try {
      let dataToUpdate; // Declare variable to hold the data to be updated

      // Check if UpdatedLocalData contains data and use it if available
      if (UpdatedLocalData) {
        dataToUpdate = UpdatedLocalData;
      } else {
        dataToUpdate = updatedsessionData;
      }

      if (dataToUpdate) {
        // Set loading state to true before making the API call
        //    await updateSession(dataToUpdate);
        // await updateSession1(
        //   dataToUpdate,
        //   formattedBillingAddresses,
        //   "7",
        //   "BillAddressList",
        //   "BillAddressList"
        // );

        await updateSession1(
          createdSessionData,
          formattedBillingAddresses,
          "7",
          "BillAddressList",
          "BillAddressList",
          "/oneGoal",
          "INCOMPLETE",
          "INCOMPLETE"
        );
        // Navigate to payment screen after successful API call
        // navigate("/payment");
      } else {
        console.error("No session data available to update.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error updating session:", error);
      // Handle error here
    } finally {
      // Set loading state to false after API call is completed (whether success or error)
    }

    setIsLoading(false); // Stop loading state
  };

  const handleApiError = (error: any) => {
    console.error("API Error: ", error);
    setIsLoading(false); // Stop loading state on error
  };

  const fetchBillingAddresses = useCallback(async () => {
    try {
      const response = makeApiCall(
        API_CALL_TYPE.GET_CALL,
        GET_BILLING_ADDRESSES(),
        handleBillingAddressesResponse,
        handleApiError,
        "",
        ""
      );
    } catch (error) {
      console.error("Error fetching payment methods:", error);
      setIsLoading(false); // Stop loading state on error
    }
  }, []);

  const handlePaymentMethodsResponse = async (data: any) => {
    const formattedPaymentMethods: PaymentMethod[] = data.data.map(
      (method: any) => ({
        paymentMethodId: method.paymentMethodId,
        asaPaymentMethodCode: method.asaPaymentMethodCode,
        isDefault: method.isDefault,
        paymentMethodName: method.paymentMethodName,
        paymentType: method.paymentType,
        routingNumber: method.routingNumber,
        last4DigitsOfAccountNumber: method.last4DigitsOfAccountNumber,
        bankName: method.bankName,
        cardType: method.cardType,
        cardNumber: method.cardNumber,
        accountNumber: method.accountNumber,
        last4DigitsOfCardNumber: method.last4DigitsOfCardNumber,
        firstName: method.firstName,
        middleName: method.middleName,
        lastName: method.lastName,
        cardHolderName: method.cardHolderName,
        expirationYear: method.expirationYear,
        expirationMonth: method.expirationMonth,
      })
    );

    setPaymentMethods(formattedPaymentMethods);

    try {
      // Set loading state to true before making the API call

      await updateSession1(
        createdSessionData,
        formattedPaymentMethods,
        "3",
        "PaymentMethodList",
        "PaymentMethodList",
        "/PaymentList",
        "INCOMPLETE",
        "INCOMPLETE"
      );
    } catch (error) {
      setIsLoading(false);
      console.error("Error updating session:", error);
      // Handle error here
    } finally {
      // Set loading state to false after API call is completed (whether success or error)
    }
  };

  const fetchPaymentMethods = useCallback(async () => {
    try {
      const response = makeApiCall(
        API_CALL_TYPE.GET_CALL,
        POST_PAYMENT_METHOD(),
        handlePaymentMethodsResponse,
        handleApiError,
        "",
        ""
      );
    } catch (error) {
      console.error("Error fetching payment methods:", error);
      setIsLoading(false); // Stop loading state on error
    }
  }, []);
  const fetchSubscriptionPlans = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await makeApiCall(
        API_CALL_TYPE.GET_CALL,
        GET_SUBSCRIPTION_PLANS(),
        handleFetchPlansSuccess,
        handleFetchPlansError,
        "",
        ""
      );
    } catch (error) {
      console.error("Error fetching subscription plans:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleActiveSubscription = async (response: any) => {
    setIsLoading(false);

    if (response.status === 200) {
      const activePlanCodesSet: Set<number> = new Set(
        response?.data.map((sub: any) => Number(sub.subscriptionPlanCode))
      );

      // Example usage of activePlanCodesSet
      // console.log(activePlanCodesSet); // Output: Set { 8 }
      setActivePlanCodes(activePlanCodesSet);

      setActiveSubscriptions(response?.data?.activeSubscriptions || []);
      setActivePlans(
        response.data.map(
          (plan: { subscriptionPlanCode: any }) => plan.subscriptionPlanCode
        )
      );
    }

    // console.log(
    //   "ACtive SUBSCRIPTION IS AS FOLLOWS::" + JSON.stringify(response)
    // );

    // console.log("ALL Plans:", JSON.stringify(plansData, null, 2));
    // console.log("FETCH ALL SUBSCRIPTIONS:", JSON.stringify(allPlans, null, 2));
    // console.log("PREVIOUS SUBSCRIPTION:", JSON.stringify(prev, null, 2));
    // console.log("Full response data:", JSON.stringify(subscriptions, null, 2));
    // console.log("Config.asaFintechCode:", Config.asaFintechCode);
    // console.log("Type of Config.asaFintechCode:", typeof Config.asaFintechCode);

    // console.log("ndfdkfkdlfj" + JSON.stringify(subscriptions));

    //   Log each subscription's asaFintechCode
    // subscriptions.forEach((sub: { asaFintechCode: any }) =>
    //   // console.log("Subscription asaFintechCode:", sub.asaFintechCode)
    // );

    // Filter subscriptions based on whether their asaFintechCode matches config.asaFintechCode
  };

  const handleACtiveSubscriptionError = (error: any) => {
    // navigate("/error", { state: "CRB AUTHENTICATION ERROR" });
  };

  const fetchActiveSubscriptions = useCallback(async () => {
    try {
      setIsLoading(true);
      // Modify your API call to include subscriptionPlanCode if necessary
      const response = await makeApiCall(
        API_CALL_TYPE.GET_CALL,
        GET_ACTIVE_SUBSCRIPTION(), // Adjust this if API requires the code
        handleActiveSubscription,
        handleACtiveSubscriptionError,
        "",
        ""
      );
    } catch (error) {
      //    console.error("Error fetching subscription plans:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const updateSessionfintech = async (
    _createdSessionDataifany: any,
    data: any,
    pageNUmber: string,
    pageName: string,
    pageTitle: string,
    pageDescription: string,
    pageUrl: string,
    pageStatusMessage: string
  ) => {
    dispatch(setLoading(true));
    setIsLoading(true);
    const updateRequest: SessionUpdateRequest = {
      asaSessionCode: Config.sessionID,
      asaConsumerCode: Config.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT-ONEGOAL",
      pageCount: _createdSessionDataifany?.pageCount,
      status: "Incomplete",
      statusMessage: _createdSessionDataifany?.statusMessage,
      sessionPages: [
        {
          asaSessionCode: Config.sessionID,

          pageNumber: pageNUmber, // Page number, could be a string or a number depending on your API requirements
          pageName: pageName, // Name of the page (e.g., "Landing")
          pageTitle: pageTitle, // Title of the page (e.g., "Select Package")
          pageDescription: pageDescription, // Description of the page
          pageUrl: pageUrl, // URL of the page
          pageStatus: "Complete", // Status of the page (e.g., "New")
          pageStatusMessage: pageStatusMessage, // Status message for the page
          pageData: JSON.stringify(data), // Serialize the selected plan data
        },
      ],
    };

    try {
      const sessionID: string = queryClient.getQueryData("sessionID") as string;

      if (!sessionID) {
        throw new Error("Session ID not found");
      }

      const response = await makeApiCall(
        API_CALL_TYPE.PUT_CALL,
        UPDATE_SESSION(),
        handleUpdateSessionResponse,
        handleUpdateSessionError,
        "",
        "",
        updateRequest
      );

      return response;
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error updating session:"));

      throw error;
    }
  };

  const handleAllFintechSuccess = async (data: any) => {
    // console.log("ALL FINTECHS ..." + JSON.stringify(data));

    const filtered_data = data?.data?.filter(
      (item: any) => item["asaFintechCode"] === Config.asaFicode
    );

    // Extract fintechName from filtered records
    const fintechNames = filtered_data.map((item: any) => item["fintechName"]);

    // console.log("Filtered Fintech Names: " + JSON.stringify(fintechNames));

    // console.log(
    //   "Filtered Fintech record is here:: " + JSON.stringify(filtered_data)
    // );

    await updateSessionfintech(
      createdSessionData,
      { Landing: "true" }, // Correctly formatted object
      "81",
      "Landingyes",
      "Landingyes",
      "/Landingyes",
      "INCOMPLETE",
      "INCOMPLETE"
    );

    await updateSessionfintech(
      createdSessionData,
      filtered_data,
      "91",
      "FintechName",
      "FintechName",
      "/FintechName",
      "INCOMPLETE",
      "INCOMPLETE"
    );

    // if (data && data.data) {
    //   setPlans(data.data);

    //   await updateSession(
    //     createdSessionData,
    //     data.data,
    //     "1",
    //     "GOALS PLANS",
    //     "USER SELECTED PLAN",
    //     "/oneGoal",
    //     "INCOMPLETE",
    //     "INCOMPLETE"
    //   );
    // } else {
    //   setPlans([]);
    // }
  };

  const handleAllFintechError = (error: any) => {};

  const fetchAllFintech = useCallback(async () => {
    // Define states for loading and error handling
    // const [isLoading, setIsLoading] = useState(false);
    // const [error, setError] = useState<string | null>(null);
    // const [data, setData] = useState<any>(null); // Define a more specific type if possible

    // setIsLoading(true);

    try {
      //  setIsLoadingAPI(true);
      //  setIsLoading(true);
      const response = await makeApiCall(
        API_CALL_TYPE.GET_CALL,
        GET_ALL_FINTECHS(),
        handleAllFintechSuccess,
        handleAllFintechError,
        "",
        ""
      );
    } catch (error) {
      setIsLoading(false); // Always executed, regardless of success or failure
    }
  }, []);

  const handleSessionResponse = async (response: any) => {
    // console.log("Authentication ", "" + JSON.stringify(response));
    // // Handle successful session response
    setIsLoading(false);
    if (response && response.data && response.data.asaSessionCode) {
      // Session ID is present, trigger update call or perform other actions
      queryClient.setQueryData("sessionData", response);
      setcreatedSessionData(response?.data);
      showCustomToast(response?.message, false);
      // Call fetchBillingAddresses(),
      await fetchAllFintech();
      await fetchActiveSubscriptions();
      await Promise.all([
        fetchSubscriptionPlans(),
        fetchBillingAddresses(),
        fetchPaymentMethods(),

        updateSession(response?.data),
      ]);
      await fetchBillingAddresses();
      await updateSession(response?.data);
    } else if (response && response?.status == "400") {
      // Handle the invalid session error here,
      console.error("Invalid session error:", response.message);
      // You can show a toast message to the user or navigate to an error page
      showCustomToast("Invalid session. Please start again.", true);
      navigate("/error", { state: "Invalid Session" });
    } else {
      setIsLoading(false);
      // Session ID not found in the response
      console.log("Session ID not found in the response");
    }
  };

  const handleBackClick = () => {
    if (UpdatedLocalData) {
      const sessionPages = UpdatedLocalData.data.sessionPages;
      let backActionDynamicLink = null;

      for (let i = 0; i < sessionPages.length; i++) {
        if (sessionPages[i].pageNumber === 0) {
          const pageData = JSON.parse(sessionPages[i].pageData);
          backActionDynamicLink = pageData.backActionDynamicLink;
          break;
        }
      }

      if (backActionDynamicLink) {
        window.location.href = backActionDynamicLink;
      } else {
        console.error("Back action dynamic link not found.");
      }
    } else {
      console.error("UpdatedLocalData is not available.");
    }
  };

  /*
   * Handling errors in the response callback after a
   * GET request to the session endpoint
   */

  const handleSessionError = (error: any) => {
    navigate("/error", { state: "CRB AUTHENTICATION ERROR" });
  };

  function scrollToSection(sectionId: string): () => void {
    return function () {
      // Find the target section by ID
      const section = document.getElementById(sectionId);
      // Scroll to the target section smoothly
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    };
  }
  const slides: Slide[] = [
    {
      icon: mobile,
      title: "Move your money faster!",
      description:
        "Withdraw funds from your Goals to Bank the same day for Free! Guac Gold includes 2 Free Rapid Withdrawals with absolutely no fees (normally 1.5%).",
    },
    {
      icon: slider1,
      title: "It's hard to limit yourself to two savings goals!",
      description:
        "Upgrade to Guac Gold & Save for Multiple Goals at the same time.",
    },
    {
      icon: slider2,
      title: "Monitor and Build Credit!",
      description:
        "Stay on Track with access to real-time Credit Scores and Improve your Credit with Exclusive Access to Pre-Qualified Personal Loans and Offers!",
    },
  ];

  const handleContinueClick = () => {
    setShowSubscriptionOptions(true); // Show subscription options when "Continue" is clicked
  };

  const handlePlanClick = async (plan: any) => {
    setSubscriptionType(plan?.billingCycle);
    setsubscriptionAmount(plan?.oneTimeAmount);
    queryClient.setQueryData("selectedPlan", plan);
    //  setshowPlans(true);

    if (activePlanCodes.has(plan.subscriptionPlanCode)) {
      setPopupMessage(`This Subscription plan is already Active`);
      setShowPopup(true);
    } else {
      try {
        await updateSessiongoalPlan(
          createdSessionData,
          plan,
          "2",
          "GOALS PLANS",
          "Select Package",
          "Select Package",
          "/HFK",
          "INCOMPLETE"
        );
      } catch (error) {
        setIsLoading(false);
        dispatch(setError("Error fetching session:"));
        console.error("Error fetching session:", error);
        throw error;
      }
    }

    // Navigate to the next page or perform other actions
    // navigate("/ListPayments", { state: { plan } });
  };

  return (
    <div className="flex justify-center items-center overflow-auto h-screen">
      <div
        className="w-full h-full flex flex-col bg-gradient-guac text-white relative z-20"
        style={{
          background: "linear-gradient(255.69deg, #D509B4 0%, #3CD096 100%)",
        }}
      >
        <div className="flex items-center justify-between p-4 mt-2">
          <span className="back-arrow-hfk">
            <img
              alt="back"
              onClick={handleBackClick}
              className="cursor-pointer p-2 shadow-2xl rounded-lg w-7 h-7"
              src={arrow_back_ios_new}
            />
          </span>
          <h1 className="header-title">Continue your subscription</h1>
        </div>

        <div className="flex-grow relative bg-[#121212] rounded-t-3xl mt-6 p-6 flex flex-col justify-between">
          <div className="relative mb-2">
            <img
              alt="Guac"
              src={guac}
              className="absolute -top-10 opacity-90 left-0 z-50 w-20 h-20"
            />
            <span className="custom-title-style-hfk absolute left-24 -top-2 text-[#FFFFFF]">
              Hire & Fire Your Kids
            </span>
          </div>

          <div className="flex flex-col mt-8">
            <div className="bg-[#121212] p-4">
              <ul className="space-y-6">
                <li className="flex items-start space-x-3">
                  <img alt="heart" src={favourite} className="w-6 h-6" />
                  <div>
                    <span className="custom-text-style font-poppins">
                      RECOGNITION
                    </span>
                    <p className=" font-poppins custom-text-style-initials ">
                      Build Self-Esteem with Recognition
                    </p>
                  </div>
                </li>

                <li className="flex items-start space-x-3">
                  <img alt="checkmark" src={circle} className="w-6 h-6" />
                  <div>
                    <span className="custom-text-style font-poppins">
                      REWARD - YOU'RE HIRED
                    </span>
                    <p className="custom-text-style-initials font-poppins">
                      Empower Your Kids to Learn the Value of a Dollar and Job
                      Responsibility
                    </p>
                  </div>
                </li>

                <li className="flex items-start space-x-3">
                  <img alt="cross" src={dangerous} className="w-6 h-6" />
                  <div>
                    <span className="custom-text-style font-poppins">
                      CONSEQUENCE - YOU'RE FIRED
                    </span>
                    <p className="custom-text-style-initials font-poppins">
                      Reduce Your Parenting Struggle
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          {/* Conditional rendering of the Continue button or subscription options */}
          <div className="mt-6 w-full">
            {showSubscriptionOptions && plans && plans.length > 0 ? (
              <div className="space-y-4">
                {plans.map((plan) => (
                  <button
                    key={plan.subscriptionPlanCode}
                    onClick={() => handlePlanClick(plan)}
                    className="w-full bg-[#CB17B2] text-white py-3 rounded-lg flex justify-between items-center px-4"
                  >
                    <div className="text-left">
                      <div className="flex items-center">
                        <p className="text-lg font-bold">
                          {plan.billingCycle === "Monthly"
                            ? `$${plan.recurringAmount} / month`
                            : `$${plan.recurringAmount} / year`}
                        </p>
                        {plan.billingCycle === "Annually" && (
                          <span className="bg-[#751067] text-white px-2 py-1 ml-2 rounded-full text-sm font-bold">
                            Save ${18}
                          </span>
                        )}
                      </div>
                      <p className="text-sm font-light">
                        {plan.billingCycle === "Monthly"
                          ? "Billed monthly"
                          : "Billed annually"}
                      </p>
                    </div>
                    <img
                      alt="forward arrow"
                      src={forward_arrow}
                      className="w-3 h-3"
                    />
                  </button>
                ))}

                {/* Cancel anytime text */}
                <p className="text-sm text-center text-gray-400 mt-4 mb-6">
                  Cancel Anytime
                </p>
              </div>
            ) : (
              <button
                className="w-full bg-[#CB17B2] text-white py-3 rounded-lg text-lg font-bold"
                onClick={handleContinueClick}
              >
                CONTINUE
              </button>
            )}
          </div>

          {/* Enhanced Popup Modal */}
          {showPopup && (
            <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50 transition-opacity duration-300 ease-in-out">
              <div className="bg-white p-6 rounded-lg shadow-xl max-w-sm mx-4 sm:mx-8 lg:mx-12">
                <div className="flex flex-col items-center">
                  <div className="text-center mb-4">
                    <p className="font-poppins text-xl font-poppins text-gray-800">
                      {popupMessage}
                    </p>
                  </div>
                  <button
                    className="bg-[#1B9C83] text-white px-4 py-2 rounded-md font-semibold hover:bg-[#169b6b] transition-colors duration-200"
                    onClick={() => setShowPopup(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
